.result {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s;
}

.result.visible {
    z-index: 15;
    opacity: 1;
    animation: result-appear 0.8s ease-in-out 0s 1 forwards;
}

.result.hidden {
    z-index: -1;
    opacity: 0;
}

.result-box {
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 150px 80px rgba(0, 0, 0, 0.6);
    /* box-shadow: 0 0 150px 110px red; */
    background: black;
    margin-bottom: 105px;
}

.result-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 105px;
    position: relative;
}

.result-text.lose {
    margin-bottom: 123px;
}


.result-text img {
    width: 65px;
    height: 6px;
    object-fit: contain;
}

.result-text span {
    padding: 0 10px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 900;
    font-size: 18px;
}

.result-text span.small {
    font-size: 13px;
    position: absolute;
    top: -16px;
    z-index: 15;
}

.result-draw-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result-draw-text span {
    color: #ffffff
}

.result-draw-price {
    padding: 0 10px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 900;
    font-size: 22px;
}

.result-win-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result-win-text span {
    color: #ffffff
}

.result-win-price {
    padding: 0 10px;
    color: #ffe500;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 900;
    font-size: 22px;
}

@media screen and (min-width: 1960px) {
    .result {
        transform: scale(2);
    }
}

@media screen and (max-width: 600px) {

    .result-box {
        margin-bottom: 125px;
        box-shadow: 0 0 200px 100px rgba(0, 0, 0, 0.7);
    }

    .result-text {
        transform: scale(0.8);
        margin-bottom: 125px;
        transform: scale(0.75);
    }

}

@media screen and (max-height: 600px)and (min-height: 500px) {
    .result-box {
        margin-bottom: 100px !important;
    }
    .result-text {
        margin-bottom: 100px !important;
    }
}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .result-box {
            margin-bottom: 85px;
            box-shadow: 0 0 200px 100px rgba(0, 0, 0, 0.8);
        }
    
        .result-text {
            margin-bottom: 85px;
            transform: scale(0.6);
        }

        .result-text.lose {
            margin-bottom: 130px;
        }
    }
}

@media screen and (max-height: 383px) {
    .result-text.lose {
        margin-bottom: 120px;
    }
}

@media screen and (max-height: 350px) {
    .result-text.lose {
        margin-bottom: 112px;
    }
}

@media screen and (max-height: 340px) {
    .result-text.lose {
        margin-bottom: 105px;
    }
}

@media screen and (max-height: 320px) {
    .result-text.lose {
        margin-bottom: 95px;
    }
}


@keyframes result-appear {

    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1.4);
    }
}