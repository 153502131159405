.e-history-content {
    width: 500px;
    height: 510px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    background: rgb(34 34 34 / 70%);
    backdrop-filter: blur(5px);
    overflow: hidden;
}

.e-history-header {
    position: relative;
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    height: 40px;
    background: #000;
    color: white;
}


.e-history-close {
    position: absolute;
    right: 15px;
    border: 1.5px solid #fff;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.e-history-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

/* Modal History Styles */
.e-history-body {
    width: 100%;
    font-size: 12px;
    color: black;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
}

.e-history-body::-webkit-scrollbar {
    display: none;
}

.e-history-item {
    border-bottom: 1px solid #4e4e4e;
    width: 100%;
    padding: 0px 20px;
}

.e-history-item:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.e-history-item.visible {
    background-color: rgba(0, 0, 0, 0.6);
}

.e-history-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
}

.e-history-number {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
}

.e-history-number-text {
    font-size: 11px;
    padding-left: 4px;
}

.e-history-head-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.e-history-head-right span {
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.4s;
}

.e-history-head-total {
    font-size: 13px;
    color: #fff;
    padding-right: 20px;
}

.e-history-head-datetime {
    padding-right: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 103px;
}

.e-history-head-time {
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding-right: 2px;
}

.e-history-head-date {
    color: white;
    font-size: 13px;
    font-weight: 400;
}

.history-head-date {
    color: white;
    font-size: 11px;
    padding-left: 5px;
}

.e-history-active-head span {
    transform: rotate(180deg);
}


.e-history-hidden-box {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s linear;
}

.e-history-hidden-box-active {
    max-height: 650px;
    padding-bottom: 10px;
}

.e-history-reason-error {
    background: #f44336;
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
}

.e-history-transaction-balance {
    font-size: 12px;
    font-weight: bold;
    color: white;
    padding: 5px 0;
}

.e-history-refund-transactions {
    padding: 10px 0;
}

.e-history-refund-transaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #303030;
}

.e-history-refund-transaction-name {
    font-size: 12px;
    color: white;
    border: 0.5px solid #656565;
    width: 50%;
    padding: 3px 4px;
}

.e-history-refund-transaction-value {
    font-size: 12px;
    color: white;
    border: 0.5px solid #656565;
    width: 50%;
    padding: 3px 4px;
    font-weight: bold;
    font-family: "Red Hat Display", 'Google Sans', monospace;
}

.e-history-refund-transaction-title {
    font-size: 13px;
    font-weight: 1000;
    color: white;
    text-transform: uppercase;
    padding-bottom: 4px;
}

/* History table style */
.operations-table {
    color: white;
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0px;
    font-family: arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.operations-table thead,
.operations-table tbody {
    width: 100%;
}

.operations-table td,
.operations-table th {
    border: 0.5px solid #656565;
    text-align: left;
    padding: 3px 4px;
    width: 33%;
    text-transform: uppercase;
    font-family: "Red Hat Display", 'Google Sans', monospace;
}

.operations-table td.total {
    font-size: 13px;
    font-weight: 900;
}

.operations-table td.value {
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 13px;
}

.operations-table td.value.win {
    color: #8bc34a;
}

.operations-table td.total.green {
    color: #8bc34a;
    font-weight: 700;
    text-shadow: 0 0 5px #8bc34a;
}

.operations-table tr.item {
    background-color: #303030;
}

.operations-table tr.item.pass {
    background-color: #f44336;
}

/* Cards box */

.e-history-cards-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.e-history-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    color: white;
}

.e-history-cards-box-item {
    width: 100%;
    color: white;
}

.e-history-cards-box-text {
    font-weight: bold;
    margin-bottom: 3px;
    color: white;
}

.e-history-cards img {
    height: 34px;
    object-fit: contain;
    width: 23px;
}

.e-history-card-item {
    width: 23px;
    margin-right: 3px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.e-history-card-item span {
    font-size: 11px;
    font-weight: bold;
    display: block;
    height: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.e-history-card-item span img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.e-history-reason-error {
    background: #f44336;
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
}


.e-history-bottom {
    padding: 10px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
}

.e-history-bottom.initial {
    padding: 5px;
}

.e-history-load-more {
    width: 150px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 5px;
    color: white;
}

.e-history-load-more:hover {
    background: #222;
    color: #fff
}

@media screen and (min-width: 993px) {
    .history-bottom-space {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .e-history-content {
        width: 100%;
        height: 100%;
    }

    .e-history-item:hover {
        background-color: transparent;
    }

    .e-history-item.visible:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .e-history-body {
        height: calc(100% - 0px);
    }

    .history-bottom-space {
        padding-top: 100px;
    }

}

@media screen and (max-width: 600px) {
    .e-history-cards-box {
        flex-direction: column;
    }

    .e-history-content {
        background-color: transparent;
    }

    .history-bottom-space {
        padding-top: 200px;
    }
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .e-history-body {
            height: calc(100vh - 0px);
        }
    }
}