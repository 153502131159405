.insurance-container {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50vh - 150px);
    height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.insurance-container.visible {
    opacity: 1;
    z-index: 15;
    transition: opacity 0.3s linear, z-index 0.1s linear 0s;
}

.insurance-container.closed {
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s linear, z-index 0.1s linear 0.3s;
}

.insurance-container::after {
    content: "";
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 0px;
    box-shadow: 0 0 200px 150px rgba(0, 0, 0, 0.8);
    width: 300px;
    top: 140px;
}

.insurance-container-text {
    color: white;
    font-weight: 800;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}

.insurance-container-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.insurance-item-text {
    font-size: 12px;
    color: #fff;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
}

.insurance-item-text.hidden {
    opacity: 0.5;
}

.insurance-close {
    background: rgba(0, 0, 0, 0.7);
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px #000;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.insurance-close img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    transition: transform 0.4s;
}

.insurance-close img:hover {
    transform: scale(1.04);
    transition: transform 0.4s;
}

.insurance-close span {
    font-weight: 1000;
    font-size: 14px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    margin-left: 5px;
}

.insurance-container .chip.disabled {
    opacity: 0.3;
    cursor: default;
}


@media screen and (min-width: 1960px) {
    .insurance-container {
        transform: scale(1.5);
        top: calc(50vh - 200px);
    }
}