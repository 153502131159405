.rates {
    position: absolute;
    z-index: 12;
    width: 100%;
    bottom: 110px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ante {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #cc9a00;
    background: rgba(126, 95, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    box-shadow: 0 5px 5px rgba(126, 95, 0, 0.6);
    position: relative;
}

.ante-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 12px;
}

.bonus {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #cc9a00;
    background: rgba(126, 95, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    box-shadow: 0 5px 5px rgba(126, 95, 0, 0.6);
    position: relative;
}

.bonus-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 12px;
}

.bet {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 1px solid #cc9a00;
    background: rgba(126, 95, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
    box-shadow: 0 5px 5px rgba(126, 95, 0, 0.6);
    position: relative;
}

.bet-text {
    font-weight: 1000;
    position: absolute;
    z-index: -1;
    color: #cc9a00;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 12px;
}




.chip-box {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    border-radius: 50%;
    overflow: hidden;
}

.chip-value {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 900;
}




.animated-chip {
    position: absolute;
    width: 70px;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}

.animated-chip.visible {
    animation: animated-chip 0.4s ease-in-out 0s 1 forwards;
}

.animated-chip.hidden {
    animation: animated-hidden 0.4s ease-in-out 0s 1 forwards;
}

@keyframes animated-chip {

    0% {
        transform: translateY(220px);
        opacity: 1;
    }

    50% {
        transform: translateY(75px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0;
    }

}


@keyframes animated-hidden {

    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(75px);
        opacity: 1;
    }

    100% {
        transform: translateY(220px);
        opacity: 0;
    }

}



.ante-chip-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.ante-chip-box.fold {
    animation: chip-box-fold 0.7s ease-in-out 0s 1 forwards;
}

.bonus-chip-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.bonus-chip-box.fold {
    animation: chip-box-fold 0.7s ease-in-out 0s 1 forwards;
}

@keyframes chip-box-fold {

    0% {
        transform: translateY(0);
        opacity: 1;
    }

    30% {
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(-110vh);
        opacity: 0;
    }

}




.bet-chip-box {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    animation: bet-chip-box 0.6s ease-in-out 0s 1 forwards;
}

.bet-chip-box.fold {
    animation: chip-box-fold 0.6s ease-in-out 0s 1 forwards;
}

@keyframes bet-chip-box {

    0% {
        transform: translateY(150px);
    }

    50% {
        transform: translateY(75px);
    }

    100% {
        transform: translateY(0);
    }

}




.rate-win-ab {
    position: absolute;
    left: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.rate-win-ab-text {
    position: absolute;
    bottom: 60px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 20px;
    color: #FFD643;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 2px 2px 1px #b71010, 0 5px 10px rgba(0, 0, 0, 0.6);
    animation: chip-result-rate-text 0.4s ease-in-out 0.4s forwards;
    opacity: 0;
}

.rate-win-ab-chip {
    animation: chip-result-rate-box 0.4s ease-in-out 0s 1 forwards;
}

.rate-win-ab-chip.rate-win-ab-chip-bonus {
    animation: chip-result-rate-box-bonus 0.4s ease-in-out 0s 1 forwards;
}



.rate-win-bet {
    position: absolute;
    left: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.rate-win-bet-text {
    position: absolute;
    bottom: 60px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 20px;
    color: #FFD643;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 2px 2px 1px #b71010, 0 5px 10px rgba(0, 0, 0, 0.6);
    animation: chip-result-rate-text 0.4s ease-in-out 0.4s forwards;
    opacity: 0;
}

.rate-win-bet-chip {
    animation: chip-result-rate-box 0.4s ease-in-out 0s 1 forwards;
}

@keyframes chip-result-rate-box {
    from {
        transform: translateY(-110vh);
    }

    to {
        transform: translateY(20px);
    }
}

@keyframes chip-result-rate-box-bonus {
    from {
        transform: translateY(-110vh);
    }

    to {
        transform: translateY(1px);
    }
}

@keyframes chip-result-rate-text {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



.rate-buy {
    position: absolute;
    margin-right: -300px;
    width: 70px;
    height: 70px;
    animation: rate-buy 1.5s ease-in-out 0s 1 forwards;
}


@keyframes rate-buy {

    0% {
        transform: translateY(50vh);
    }

    50% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-110vh);
    }
}



.rate-insurance {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    margin-right: -600px;
}

.rate-insurance-image {
    position: absolute;
}

.rate-insurance img {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.rate-insurance-chip {
    padding-bottom: 10px;
}

.rate-insurance-text {
    position: absolute;
    font-size: 15px;
    text-align: center;
    color: white;
    bottom: -35px;
}


@media screen and (min-width: 1960px) {
    .rates {
        transform: scale(1.5);
        bottom: 220px;
    }
}


@media screen and (max-width: 600px) {

    .rates {
        bottom: 180px;
        transform: scale(0.7);
    }

    .rate-insurance {
        width: 80px;
        height: 80px;
        margin-right: -340px;
    }

    .rate-insurance img {
        width: 90px;
        height: 90px;
    }

    .rate-insurance-chip .chip-box {
        width: 50px !important;
        height: 50px !important;
    }

    .rate-insurance-chip .chip-box .chip-value {
        transform: scale(0.8);
    }

}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .rates {
            bottom: 50px;
            transform: scale(0.6);
        }

        .rate-insurance {
            width: 80px;
            height: 80px;
            margin-right: -340px;
        }

        .rate-insurance img {
            width: 90px;
            height: 90px;
        }

        .rate-insurance-chip .chip-box {
            width: 50px !important;
            height: 50px !important;
        }

        .rate-insurance-chip .chip-box .chip-value {
            transform: scale(0.8);
        }
    }
}