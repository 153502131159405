.card-container {
    width: 100px;
    height: 150px;
    background-image: linear-gradient(to right bottom, #fefdff, #fcfbfe, #faf8fe, #f8f6fd, #f6f4fd);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.dealer-card .card-container {
    width: 70px;
    height: 105px;
    border-radius: 15px;
}

.card-container-head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 50%;
    padding: 10px;
}

.dealer-card .card-container-head {
    transform: scale(0.7);
    margin-top: -7px;
    margin-left: -17px;
}

.card-container-head-text {
    color: #000;
    font-size: 50px;
    line-height: 40px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-weight: 700;
}

.card-container-head-text.red {
    color: #CD2E4B
}

.card-container-head-suit {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5px;
    margin-top: 5px;
}

.card-container-head-suit.player {
    margin-left: 5px;
    margin-top: 5px;
}

.card-container-bottom {
    width: 100%;
    height: 50%;
    margin-bottom: 5px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.dealer-card .card-container-bottom-inner {
    transform: scale(0.7);
}

.dealer-card .card-container-bottom {
    margin-right: 0px;
    margin-bottom: 0px;
}



.dealer-card-animated .card-container {
    animation: card-container-animated-image 2s ease-in-out 1s infinite forwards;
}

@keyframes card-container-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.dealer-card-animated .card-container::after {
    content: "";
    border: 16px solid transparent;
    border-bottom: 16px solid #8bc34a;
    border-left: 16px solid #8bc34a;
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 100;
    transform: rotate(-8deg);
    animation: card-container-animated-corner 0.4s linear 1s 1 forwards;
    opacity: 0;
}

@keyframes card-container-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes card-container-animated-corner {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}


@media screen and (max-width: 1220px) {
    .card-container {
        width: 90px;
        height: 135px;
    }
}

@media screen and (max-width: 992px) {
    .card-container {
        width: 80px;
        height: 120px;
    }

    .dealer-card .card-container {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .card-container-head {
        transform: scale(0.8);
        margin-top: -5px;
        margin-left: -14px;
    }

    .dealer-card .card-container-head {
        transform: scale(0.5);
        padding: 0;
        margin-top: -5px;
    }

    .card-container-bottom {
        margin-right: 5px;
        margin-bottom: 0px;
    }

    .card-container-bottom-inner {
        transform: scale(0.8);
    }

    .dealer-card .card-container-bottom {
        margin-right: -15px;
        margin-bottom: -8px;
    }

    .dealer-card .card-container-bottom-inner {
        transform: scale(0.5);
    }

    .dealer-card-animated .card-container::after {
        border: 11px solid transparent;
        border-bottom: 11px solid #8bc34a;
        border-left: 11px solid #8bc34a;
    }
}


@media screen and (max-width: 600px) {

    .dealer-card .card-container,
    .card-container {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .card-container-head,
    .dealer-card .card-container-head {
        transform: scale(0.5);
        padding: 0;
        margin-top: -5px;
        margin-left: -14px;
    }

    .card-container-bottom,
    .dealer-card .card-container-bottom {
        margin-right: -15px;
        margin-bottom: -8px;
    }

    .card-container-bottom-inner,
    .dealer-card .card-container-bottom-inner {
        transform: scale(0.5);
    }

    .dealer-card-animated .card-container::after {
        border: 11px solid transparent;
        border-bottom: 11px solid #8bc34a;
        border-left: 11px solid #8bc34a;
    }
}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .dealer-card .card-container {
            width: 30px;
            height: 45px;
            border-radius: 4px;
        }


        .card-container {
            width: 40px;
            height: 60px;
            border-radius: 5px;
        }

        .card-container-head {
            transform: scale(0.5);
            padding: 0;
            margin-top: -5px;
            margin-left: -10px;
        }

        .card-container-head-text {
            font-size: 35px;
        }

        .card-container-bottom {
            margin-right: -15px;
            margin-bottom: -10px;
        }

        .card-container-bottom-inner {
            transform: scale(0.5);
        }

        .dealer-card .card-container-head {
            transform: scale(0.4);
            padding: 0;
            margin-top: -5px;
            margin-left: -12px;
        }

        .dealer-card .card-container-bottom {
            margin-right: -25px;
            margin-bottom: -12.5px;
        }

        .dealer-card .card-container-bottom-inner {
            transform: scale(0.4);
        }

        .dealer-card-animated .card-container::after {
            border: 8px solid transparent;
            border-bottom: 8px solid #8bc34a;
            border-left: 8px solid #8bc34a;
        }
    }
}