.jackpot {
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 15px 50px;
    backdrop-filter: blur(5px);
    overflow: hidden;
    width: 250px;
    height: 92px;
}

.jackpot-title {
    font-size: 20px;
    line-height: 30px;
    color: white;
    font-weight: 1000;
    text-shadow: 0 0 10px white;
    font-family: "Red Hat Display", sans-serif;
}

.jackpot-text {
    font-size: 22px;
    line-height: 32px;
    color: #ffc107;
    font-family: "Red Hat Display";
    text-align: center;
    font-weight: 1000;
    text-shadow: 0 0 5px #ffc107;
    padding-left: 8px;
}

.jackpot-text::before {
    content: "$";
    position: absolute;
    margin-left: -16px;
}

.jackpot-text span {
    font-family: "Red Hat Display";
}

.odometer-numbers span {
    width: 16px;
}

.jackpot-coin {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: -1;
}

.jackpot-coin img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.jackpot-coins {
    position: absolute;
    left: -25px;
    bottom: -10px;
    z-index: -1;
}

.jackpot-coins img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.jackpot-money {
    position: absolute;
    right: -10px;
    bottom: -15px;
    z-index: -1;
}

.jackpot-money img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}


@media screen and (max-width: 992px) {

    .jackpot {
        top: 8px;
        right: 5px;
        padding: 5px 10px;
        width: 140px;
        height: 50px;
    }

    .jackpot-title {
        font-size: 10px;
        line-height: 10px;
        padding-bottom: 5px;
    }

    .jackpot-text {
        font-size: 13px;
        line-height: 16px;
    }

    .jackpot-text::before {
        margin-left: -10px;
    }

    .odometer-numbers span {
        width: 10px;
    }

    .jackpot-coin,
    .jackpot-coins,
    .jackpot-money {
        display: none;
    }

}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .jackpot {
            top: 10px;
            right: 5px;
            padding: 5px 10px;
            width: 140px;
            height: 50px;
        }

        .jackpot-title {
            font-size: 10px;
            line-height: 10px;
            padding-bottom: 5px;
        }

        .jackpot-text {
            font-size: 13px;
            line-height: 16px;
        }

        .odometer-numbers span {
            width: 10px;
        }

        .jackpot-coin,
        .jackpot-coins,
        .jackpot-money {
            display: none;
        }

    }
}