.payout-container {
    position: absolute;
    z-index: 0;
    height: 100vh;
    min-width: 420px;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    transform: translateX(-500px);
    transition: transform 0.5s;
}

.game-info:hover .payout-container,
.game-info.pinned .payout-container {
    transform: translateX(0);
}

.payout {
    border: 1px dashed rgba(255, 255, 255, 0.3);
    padding: 10px;
    border-radius: 10px;
    opacity: 0.9;
    transition: opacity 0.3s;
    position: relative;
}

.payout-head {
    position: absolute;
    color: white;
    top: -8px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    text-shadow: 0 0 20px black;
}

.payout-head span {
    color: #ffc107
}

.payout-rate {
    font-weight: 500;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    color: #ffc107;
    font-size: 15px;
    text-align: center;
    margin-bottom: 4px;
}

.payout-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.payout-name {
    font-weight: 300;
    font-size: 12px;
    color: #fff;
    padding-right: 2px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    width: 110px;
}

.payout-value {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    width: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.payout-combination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 140px;
    opacity: 0.7;
}

.payout-combination-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
}

.payout-combination img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.payout-combination-text {
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    padding-right: 2px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
}



/* BONUS PAYOUT STYLES */
.bonus-payout {
    border: 1px dashed rgba(255, 255, 255, 0.3);
    padding: 20px 10px 10px 10px;
    border-radius: 10px;
    transition: opacity 0.3s;
    position: relative;
    margin-top: 30px;
}

.bonus-payout.hidden {
    opacity: 0.6;
}

.bonus-payout-head {
    position: absolute;
    color: #ffc107;
    top: -10px;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 1000;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    text-shadow: 0 0 10px #ffc107;
    letter-spacing: 2px;
}

.bonus-payout-rate {
    font-weight: 500;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    color: #ffc107;
    font-size: 15px;
    text-align: center;
    margin-top: -3px;
    margin-bottom: 4px;
}

.bonus-payout-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.bonus-payout-name {
    font-size: 13px;
    color: white;
    padding-right: 2px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    width: 140px;
    font-weight: 700;
}

.bonus-payout-value {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    width: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bonus-payout-value.jackpot-value {
    font-weight: 1000;
    color: #ffc107;
    text-shadow: 0 0 10px #ffc107;
}

.bonus-payout-box-maxpay {
    font-size: 12px;
    color: white;
    text-align: center;
    margin: 20px 10px 0 10px;
}

.bonus-payout-box-maxpay span {
    font-weight: bold;
    text-shadow: 0 0 10px white;
}

@media screen and (max-width: 992px) {

    .payout-container {
        display: none;
    }

    .payout.hidden {
        opacity: 1;
    }

    .payout-content .payout-container {
        display: block;
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateX(0);
        min-width: 100%;
        padding: 0;
        background: none;
        height: auto;
    }

    .payout-content-bonus .payout {
        display: none;
    }

    .payout-content-bonus .bonus-payout {
        display: block;
        margin-top: 0;
    }

    .payout-content-table .payout {
        display: block;
    }

    .payout-content-table .bonus-payout {
        display: none;
    }
}

@media screen and (max-width: 600px) {

    .bonus-payout-head {
        display: none;
    }

    .bonus-payout-rate {
        font-size: 12px;
        position: absolute;
        top: -5px;
        text-align: center;
        width: 100%;
        left: 0;
        right: 0;
        font-weight: bold;
    }

}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .bonus-payout-head {
            display: none;
        }

        .bonus-payout-rate {
            font-size: 12px;
            position: absolute;
            top: -5px;
            text-align: center;
            width: 100%;
            left: 0;
            right: 0;
            font-weight: bold;
        }
    }
}

.mobile-payout {
    width: 100%;
    height: 100%;
}

.payout-content {
    padding: 20px 27px;
}

.switch-selector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 15px;
}

.switch-selector .switch {
    width: 43%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid rgb(105, 105, 105);
    opacity: 0.3;
    transition: transform 0.4s;
}

.switch-selector .switch.active {
    opacity: 1;
    box-shadow: 0 0 3px #ffc008;
    transform: scale(1.02);
    transition: transform 0.4s;
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .mobile-payout {
            overflow: scroll;
        }
    }
}