.bonus--win-container {
    position: absolute;
    left: calc(50vw - 200px);
    top: calc(50vh - 100px);
    width: 400px;
    height: 200px;
    z-index: 20;
}

.bonus--double-win-container {
    position: absolute;
    left: calc(50vw - 410px);
    top: calc(50vh - 100px);
    width: 820px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bonus--win-box {
    background: rgba(0, 0, 0, 0.8);
    width: 400px;
    height: 200px;
    border-radius: 30px;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 20px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 10px;
}

.bonus--win-box-number {
    color: white;
    position: absolute;
    top: 20px;
    z-index: 1;
    font-size: 14px;
}

.bonus--win-game {
    font-weight: 1000;
    font-family: 'Red Hat Display', 'Google Sans';
    color: white;
    text-shadow: 0 0 10px white;
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
}

.bonus--win-value {
    font-weight: 1000;
    font-family: 'Red Hat Display';
    color: #ffc107;
    text-shadow: 0 0 10px #ffc107;
    text-align: center;
    font-size: 30px;
}


.bonus--win-coin {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: -1;
}

.bonus--win-coin img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.bonus--win-coins {
    position: absolute;
    left: -25px;
    bottom: -10px;
    z-index: -1;
}

.bonus--win-coins img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.bonus--win-money {
    position: absolute;
    right: -20px;
    bottom: -15px;
    z-index: -1;
}

.bonus--win-money img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}



@media screen and (max-width: 1000px) {
    .bonus--double-win-container {
        position: absolute;
        left: calc(50vw - 200px);
        top: calc(50vh - 210px);
        width: 400px;
        height: 420px;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {

    .bonus--win-container {
        position: absolute;
        left: calc(50vw - 150px);
        top: calc(50vh - 100px);
        width: 300px;
        height: 200px;
    }

    .bonus--double-win-container {
        position: absolute;
        left: calc(50vw - 150px);
        top: calc(50vh - 210px);
        width: 300px;
        height: 420px;
        flex-direction: column;
    }

    .bonus--win-box {
        width: 300px;
        height: 200px;
    }

    .bonus--win-coin {
        position: absolute;
        right: -5px;
        top: -5px;
        z-index: -1;
    }

    .bonus--win-coin img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .bonus--win-coins {
        position: absolute;
        left: -20px;
        bottom: -8px;
        z-index: -1;
    }

    .bonus--win-coins img {
        width: 75px;
        height: 75px;
    }

    .bonus--win-money {
        right: -10px;
        bottom: -15px;
    }

    .bonus--win-money img {
        width: 75px;
        height: 75px;
    }

}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .bonus--win-container {
            left: calc(50vw - 125px);
            top: calc(50vh - 75px);
            width: 250px;
            height: 150px;
        }

        .bonus--double-win-container {
            left: calc(50vw - 260px);
            top: calc(50vh - 150px);
            width: 520px;
            height: 300px;
            flex-direction: row-reverse;
        }

        .bonus--win-box {
            width: 250px;
            height: 150px;
        }

        .bonus--win-coin {
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: -1;
        }

        .bonus--win-coin img {
            width: 40px;
            height: 40px;
            object-fit: contain;
        }

        .bonus--win-coins {
            position: absolute;
            left: -10px;
            bottom: -12px;
            z-index: -1;
        }

        .bonus--win-coins img {
            width: 50px;
            height: 50px;
        }

        .bonus--win-money {
            right: -5px;
            bottom: -12px;
        }

        .bonus--win-money img {
            width: 50px;
            height: 50px;
        }

    }
}






@keyframes firework {
    0% {
        transform: translate(var(--x), var(--initialY));
        width: var(--initialSize);
        opacity: 1;
    }

    50% {
        width: 0.5vmin;
        opacity: 1;
    }

    100% {
        width: var(--finalSize);
        opacity: 0;
    }
}

.firework-container {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.firework,
.firework::before,
.firework::after {
    --initialSize: 0.5vmin;
    --finalSize: 55vmin;
    --particleSize: 0.2vmin;
    --color1: yellow;
    --color2: khaki;
    --color3: white;
    --color4: lime;
    --color5: gold;
    --color6: mediumseagreen;
    --y: -30vmin;
    --x: -50%;
    --initialY: 60vmin;
    content: "";
    animation: firework 2s 1 forwards;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, var(--y));
    width: var(--initialSize);
    aspect-ratio: 1;
    background:

        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 50% 0%,
        radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 50%,
        radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 50% 100%,
        radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 0% 50%,

        /* bottom right */
        radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 80% 90%,
        radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 95% 90%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 90% 70%,
        radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 100% 60%,
        radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 55% 80%,
        radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 70% 77%,

        /* bottom left */
        radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 22% 90%,
        radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 45% 90%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 70%,
        radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 10% 60%,
        radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 31% 80%,
        radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 28% 77%,
        radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 13% 72%,

        /* top left */
        radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 80% 10%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 95% 14%,
        radial-gradient(circle, var(--color2) var(--particleSize), #0000 0) 90% 23%,
        radial-gradient(circle, var(--color3) var(--particleSize), #0000 0) 100% 43%,
        radial-gradient(circle, var(--color4) var(--particleSize), #0000 0) 85% 27%,
        radial-gradient(circle, var(--color5) var(--particleSize), #0000 0) 77% 37%,
        radial-gradient(circle, var(--color6) var(--particleSize), #0000 0) 60% 7%,

        /* top right */
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 22% 14%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 45% 20%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 33% 34%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 10% 29%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 31% 37%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 28% 7%,
        radial-gradient(circle, var(--color1) var(--particleSize), #0000 0) 13% 42%;
    background-size: var(--initialSize) var(--initialSize);
    background-repeat: no-repeat;
    width: 100%;
}

.firework::before {
    --x: -50%;
    --y: -50%;
    --initialY: -50%;
    transform: translate(-50%, -50%) rotate(40deg) scale(4) rotateY(40deg);
}

.firework::after {
    --x: -50%;
    --y: -50%;
    --initialY: -50%;
    transform: translate(-50%, -50%) rotate(170deg) scale(4) rotateY(-30deg);
}

.firework:nth-child(2) {
    --x: 30vmin;
}

.firework:nth-child(2),
.firework:nth-child(2)::before,
.firework:nth-child(2)::after {
    --color1: pink;
    --color2: violet;
    --color3: fuchsia;
    --color4: orchid;
    --color5: plum;
    --color6: lavender;
    --finalSize: 40vmin;
    left: 30%;
    top: 60%;
    animation-delay: -0.25s;
}

.firework:nth-child(3) {
    --x: -30vmin;
    --y: -50vmin;
}

.firework:nth-child(3),
.firework:nth-child(3)::before,
.firework:nth-child(3)::after {
    --color1: cyan;
    --color2: lightcyan;
    --color3: lightblue;
    --color4: PaleTurquoise;
    --color5: SkyBlue;
    --color6: lavender;
    --finalSize: 35vmin;
    left: 70%;
    top: 60%;
    animation-delay: -0.4s;
}




.firework:nth-child(4) {
    --x: -30vmin;
    --y: -50vmin;
}

.firework:nth-child(4),
.firework:nth-child(4)::before,
.firework:nth-child(4)::after {
    --finalSize: 35vmin;
    left: 10%;
    top: 50%;
    animation-delay: -0.55s;
}

.firework:nth-child(5) {
    --x: 30vmin;
}

.firework:nth-child(5),
.firework:nth-child(5)::before,
.firework:nth-child(5)::after {
    --color1: pink;
    --color2: violet;
    --color3: fuchsia;
    --color4: orchid;
    --color5: plum;
    --color6: lavender;
    --finalSize: 40vmin;
    left: 90%;
    top: 60%;
    animation-delay: -0.65s;
}

.firework:nth-child(6) {
    --x: 30vmin;
}

.firework:nth-child(6),
.firework:nth-child(6)::before,
.firework:nth-child(6)::after {
    --color1: cyan;
    --color2: lightcyan;
    --color3: lightblue;
    --color4: PaleTurquoise;
    --color5: SkyBlue;
    --color6: lavender;
    --finalSize: 35vmin;
    left: 10%;
    top: 40%;
    animation-delay: -0.75s;
}