.preloader {
    background-image: radial-gradient(circle, #1d2025, #181a20, #13151b, #0d0d17, #050311);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
}

.preloader .full-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* filter: blur(3px); */
    /* -webkit-filter: blur(3px); */
    opacity: 0.8 !important;
}

.preloader-logo {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.preloader-logo img {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.preloader-logo-text {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preloader-logo-text .vip {
    font-size: 24px;
    color: var(--primary);
    font-weight: bold;
    margin-bottom: 6px;
}

.preloader-logo-text p {
    font-size: 60px;
    color: white;
    font-family: 'Red Hat Display';
    font-weight: 900;
    /*
    font-family: cursive;
    font-stretch: ultra-expanded;
    font-weight: bold;
    */
}

.preloader-logo-text span {
    display: block;
    font-size: 46px;
    color: white;
    text-align: center;
    font-family: 'Red Hat Display';
    font-weight: 900;
    /*
    font-family: cursive;
    font-stretch: ultra-expanded;
    font-weight: bold;
    */
}


.preloader-indicator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin-top: 25px;
}

.preloader-indicator .line {
    width: 100%;
}

.preloader-indicator .star {
    position: absolute;
    animation-name: linear-animation;
    left: 0;
    z-index: 1 !important;
    opacity: 1 !important;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
}

.preloader-indicator-bar {
    width: 100%;
    height: 10px;
    position: absolute;
    z-index: 1;
    background-color: var(--primary);
    border-radius: 10px;
    animation-name: linear-animation;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
}

.preloader .support {
    color: #A8A8A8;
    font-size: 18px;
    /* font-weight: 400; */
    z-index: 1;
    margin-top: 40px;
    margin-bottom: 15px;
}

.preloader .makao-logo {
    width: 180px;
    object-fit: contain;
    z-index: 1;
}

@keyframes linear-animation {
    0% {
        left: 0%;
        transform: rotate(90deg);
    }

    50% {
        left: 50%;
        transform: rotate(180deg);
    }

    75% {
        left: 75%;
        transform: rotate(270deg);
    }

    100% {
        left: 94%;
        transform: rotate(360deg);
    }
}


/* Mobile version */
@media screen and (max-width: 800px) {
    .preloader-logo img {
        width: 100px;
        height: 100px;
    }

    .preloader-logo-text .vip {
        font-size: 16px;
    }

    .preloader-logo-text p {
        font-size: 26px;
        margin-bottom: 4px;
    }

    .preloader-logo-text span {
        font-size: 26px;
    }
}

@media screen and (max-width: 900px) {
    @media (orientation: landscape) {
        .preloader-logo img {
            width: 100px;
            height: 100px;
        }

        .preloader-logo img {
            width: 100px;
            height: 100px;
        }

        .preloader-logo-text .vip {
            font-size: 16px;
        }

        .preloader-logo-text p {
            font-size: 26px;
            margin-bottom: 4px;
        }

        .preloader-logo-text span {
            font-size: 26px;
        }
    }
}



.error {
    background-image: linear-gradient(270deg, #29253c, #232235, #1e1f2f, #1a1c28, #171921);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding-bottom: 50px;
    padding: 20px;
}

.error img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.error-text {
    font-size: 40px;
    font-weight: 900;
    margin-top: 40px;
    color: white;
    text-align: center;
}

.error-description {
    color: #c0c0c0;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.error-button {
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    width: 200px;
    background: #434558;
    margin-top: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.5s;
}

.error-button.reload-button {
    background: #434558;
}

.error-button:hover {
    transform: scale(1.05);
}


@media screen and (max-width: 800px) {
    .error img {
        width: 70px;
        height: 70px;
    }

    .error-text {
        font-size: 26px;
    }

    .error-description {
        font-size: 12px;
    }
}


@media screen and (min-width: 1960px) {

    .preloader {
        transform: scale(1.5);
    }

    .error {
        transform: scale(1.5);
    }
}