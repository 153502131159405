/* PLAYER CARD GAME STYLES */

.player-container {
    width: 550px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    position: relative;
}


/* CONTAINER HEAD STYLES */
.player-container-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.player-container-title {
    font-size: 15px;
    font-weight: 700;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-game-status {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-weight: 900;
    color: white;
    border-radius: 10px;
    font-size: 16px;
    background: #8bc34a;
    backdrop-filter: blur(4px);
    font-family: "Red Hat Display", 'Google Sans', monospace;
}

.player-game-status.red {
    background-color: #f44336;
}




/* CARD STYLES */
.player-cards {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 550px;
}

.player-cards.six-card {
    margin-left: -50px;
    transition: margin-left 0.4s;
}

.player-card {
    width: 100px;
    height: 150px;
    margin: 0 5px;
    animation: player-card 0.3s ease-in-out 0s 1 forwards;
}

.player-card.remove {
    animation: player-card-remove 0.3s ease-in-out 0s 1 forwards;
    width: 0;
    transition: width 0.4s;
    margin: 0;
    overflow: hidden;
}

.player-card img {
    width: 100px;
    height: 150px;
    object-fit: cover;
    box-shadow: 0 0 10px #222;
    border-radius: 20px;
}

.player-flip-card {
    background-color: transparent;
    width: 100px;
    height: 150px;
    perspective: 1000px;
}

.player-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-flip-card.open .player-flip-card-inner {
    transform: rotateY(180deg);
}

.player-flip-card-front,
.player-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-flip-card-front {
    color: black;
}

.player-flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

@keyframes player-card {

    0% {
        transform: translateX(100vw) translateY(-100vh);
    }

    100% {
        transform: translateX(0) translateY(0);
    }

}

@keyframes player-card-remove {

    0% {
        transform: translateX(0) translateY(0);
    }

    100% {
        transform: translateX(-100vw) translateY(-100vh);
    }

}



/* ANIMATED STYLES */
.player-card .player {
    height: 150px;
}

.player-card .animated,
.player-card .sixth {
    animation: player-card-animated-image 2s linear 1s infinite forwards;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    height: 150px;
    transform: translateY(0);
}

.player-card .animated::after,
.player-card .sixth::after {
    content: "";
    border: 16px solid transparent;
    border-bottom: 16px solid #8bc34a;
    border-left: 16px solid #8bc34a;
    position: absolute;
    left: 0;
    bottom: -5px;
    z-index: 100;
    transform: rotate(-8deg);
    animation: player-card-animated-corner 0.4s linear 1s 1 forwards;
    opacity: 0;
}

@keyframes player-card-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes player-card-animated-corner {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}



/* PLAYER ADDITIONAL STATUS STYLES */
.player-card-additional {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    background: #ff9800;
    box-shadow: 0 0 20px #ff9800;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
}

.player-card-additional .arrow {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-left: 3px;
    box-shadow: none !important;
}





/* PLAYER SPACE STYLES */
.player-spaces {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 550px;
    position: absolute;
    z-index: -1;
    top: 50px;
}

.player-space {
    width: 100px;
    height: 150px;
    border: 1px dashed #a58844;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 5px;
    opacity: 0.6;
}

.player-space.space-shadow {
    animation: player-space-shadow 2s ease-in-out 1s infinite forwards;
}

.player-space-circle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #af9147;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-space-diamond {
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background: #212133;
}

.player-space-round {
    border: 1px dashed #af9147;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-left: -5px;
    opacity: 0.4;
}



@keyframes player-space-shadow {

    0% {
        box-shadow: 0 0 10px #9e9e9e;
    }

    50% {
        box-shadow: 0 0 20px #9e9e9e;
    }

    100% {
        box-shadow: 0 0 10px #9e9e9e;
    }

}

@media screen and (min-width: 1960px) {
    .player-container {
        transform: scale(1.5);
    }
}


@media screen and (max-width: 1220px) {

    .player-container {
        width: 500px;
    }

    .player-container-head {
        height: 30px;
        margin-top: 10px;
    }

    .player-cards {
        width: 500px;
    }

    .player-card {
        width: 90px;
        height: 135px;
    }

    .player-card .player {
        height: 135px;
    }

    .player-flip-card {
        width: 90px;
        height: 135px;
    }

    .player-card img {
        width: 90px;
        height: 135px;
    }

    .player-card .animated,
    .player-card .sixth {
        height: 135px;
    }

    .player-spaces {
        width: 500px;
    }

    .player-space {
        width: 90px;
        height: 135px;
    }
}


@media screen and (max-width: 992px) {

    .player-container {
        width: 450px;
    }

    .player-container-head {
        height: 30px;
        margin-top: 10px;
    }

    .player-cards {
        width: 450px;
    }

    .player-card {
        width: 80px;
        height: 120px;
    }

    .player-card .player {
        height: 120px;
    }

    .player-flip-card {
        width: 80px;
        height: 120px;
    }

    .player-card img {
        width: 80px;
        height: 120px;
    }

    .player-card .animated,
    .player-card .sixth {
        height: 120px;
    }

    .player-spaces {
        width: 450px;
    }

    .player-space {
        width: 80px;
        height: 120px;
    }
}


@media screen and (max-width: 600px) {

    .player-container {
        width: 245px;
    }

    .player-container-head {
        height: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .player-container-title {
        font-size: 13px;
        height: 25px;
        align-items: center;
    }

    .player-cards {
        width: 245px;
        transition: margin-left 0.4s;
    }

    .player-cards.six-card {
        margin-left: -24.5px;
        transition: margin-left 0.4s;
    }

    .player-card {
        width: 45px;
        height: 67.5px;
        margin: 0 2px;
    }

    .player-card .player {
        height: 67.5px;
    }

    .player-flip-card {
        width: 45px;
        height: 67.5px;
    }

    .player-card img {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .player-card .animated,
    .player-card .sixth {
        height: 67.5px;
        border-radius: 5px;
    }

    .player-card-additional {
        right: 1px;
        top: 1px;
        font-size: 9px;
        width: 13px;
        height: 13px;
        border-radius: 9px;
        padding-right: 1px;
    }

    .player-card-additional .arrow {
        width: 10px;
        height: 10px;
        margin-left: 1.49px;
    }

    .player-spaces {
        width: 245px;
        top: 40px;
    }

    .player-space {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
        margin: 0 2px;
        opacity: 0.2;
    }

    .player-space-circle {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .player-space-round {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .player-game-status {
        height: 20px;
        padding: 0 12px;
        font-size: 12px;
    }

    .player-game-status.smaller {
        font-size: 9px;
        padding: 0 4px;
    }

    .player-card .animated::after,
    .player-card .sixth::after {
        border: 11px solid transparent;
        border-bottom: 11px solid #8bc34a;
        border-left: 11px solid #8bc34a;
    }
}

@media screen and (max-height: 600px)and (min-height: 500px) {
    .player-container {
        transform: scale(1.5);
    }
}


@media screen and (max-height: 600px) {

    @media (orientation: landscape) {
        .player-container {
            width: 220px;
            top: 20px;
        }

        .player-container-head {
            height: 20px;
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 0px;
            display: flex;
            align-items: center;
        }

        .player-container-title {
            font-size: 10px;
            height: 20px;
            align-items: center;
        }

        .player-cards {
            width: 220px;
            transition: margin-left 0.4s;
        }

        .player-cards.six-card {
            margin-left: -20px;
            transition: margin-left 0.4s;
        }

        .player-card {
            width: 40px;
            height: 60px;
            margin: 0 2px;
        }

        .player-card .player {
            height: 60px;
        }

        .player-flip-card {
            width: 40px;
            height: 60px;
        }

        .player-card img {
            width: 40px;
            height: 60px;
            border-radius: 5px;
        }

        .player-card .animated,
        .player-card .sixth {
            height: 60px;
            border-radius: 5px;
        }

        .player-card-additional {
            right: 1px;
            top: 1px;
            font-size: 9px;
            width: 13px;
            height: 13px;
            border-radius: 9px;
            padding-right: 1px;
        }

        .player-card-additional .arrow {
            width: 10px;
            height: 10px;
            margin-left: 1.49px;
        }

        .player-spaces {
            width: 220px;
            top: 35px;
        }

        .player-space {
            width: 40px;
            height: 60px;
            border-radius: 5px;
            margin: 0 2px;
        }

        .player-space-circle {
            width: 12px;
            height: 12px;
            border-radius: 6px;
        }

        .player-space-round {
            width: 12px;
            height: 12px;
            border-radius: 6px;
        }


        .player-game-status {
            height: 17px;
            padding: 0 7px;
            font-size: 10px;
        }

        .player-game-status.smaller {
            font-size: 9px;
            padding: 0 4px;
        }

        .player-card .animated::after,
        .player-card .sixth::after {
            border: 11px solid transparent;
            border-bottom: 11px solid #8bc34a;
            border-left: 11px solid #8bc34a;
        }
    }

}


@media screen and (max-height: 320px) {
    .player-container {
        top: 0px;
    }
}