.dealer-container {
    width: 400px;
    height: 140px;
    position: relative;
}

.dealer-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin-bottom: 10px;
}

.dealer-container-title {
    font-size: 12px;
    font-weight: 1000;
    padding: 0 5px;
    color: white;
    font-family: "Red Hat Display", 'Google Sans', monospace;
}

.dealer-game-status {
    padding: 4px 8px;
    font-weight: 900;
    color: white;
    border-radius: 10px;
    font-size: 13px;
    background: #8bc34a;
    backdrop-filter: blur(4px);
    font-family: "Red Hat Display", 'Google Sans', monospace;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dealer-game-status.red {
    background-color: #f44336;
}

.dealer-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
}

.dealer-card {
    width: 70px;
    height: 105px;
    margin: 0 5px;
    animation: dealer-card 0.3s ease-in-out 0s 1 forwards;
}

.dealer-card img {
    width: 70px;
    height: 105px;
    object-fit: contain;
}

.dealer-flip-card {
    background-color: transparent;
    width: 70px;
    height: 105px;
    perspective: 1000px;
}

.dealer-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dealer-flip-card.open .dealer-flip-card-inner {
    transform: rotateY(180deg);
}

.dealer-flip-card-front,
.dealer-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dealer-flip-card-front {
    color: black;
}

.dealer-flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

.dealer-flip-card-back.blured {
    opacity: 1;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    overflow: hidden;
    border-radius: 7px;
}

.dealer-flip-card-back .removed {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 10;
}

@keyframes dealer-card {

    0% {
        transform: translateX(100vw) translateY(-100vh);
    }

    100% {
        transform: translateX(0) translateY(0);
    }

}


.dealer-spaces {
    display: flex;
    justify-content: center;
    align-self: center;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 35px
}

.dealer-space {
    width: 70px;
    height: 105px;
    border-radius: 13px;
    border: 1px dashed #a58844;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
}

.dealer-space-circle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #af9147;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dealer-space-diamond {
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background: #212133;
}

.dealer-space-round {
    border: 1px dashed #af9147;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin-left: -5px;
}


@keyframes dealer-card-animated-image {

    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (min-width: 1960px) {
    .dealer-container {
        transform: scale(1.5);
    }
}


@media screen and (max-width: 992px) {
    .dealer-container {
        width: 245px;
    }

    .dealer-container-header {
        height: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .dealer-container-title {
        font-size: 13px;
        height: 25px;
        align-items: center;
    }

    .dealer-cards {
        width: 245px;
    }

    .dealer-card {
        width: 45px;
        height: 67.5px;
        margin: 0 2px;
    }

    .dealer-card .dealer {
        height: 67.5px;
    }

    .dealer-flip-card {
        width: 45px;
        height: 67.5px;
    }

    .dealer-card img {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .dealer-card .animated {
        height: 67.5px;
        border-radius: 5px;
    }

    .dealer-spaces {
        width: 245px;
        top: 40px;
    }

    .dealer-space {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
        margin: 0 2px;
    }

    .dealer-game-status {
        height: 20px;
        padding: 0 12px;
        font-size: 12px;
    }

}

@media screen and (max-width: 600px) {
    .dealer-container {
        width: 245px;
    }

    .dealer-container-header {
        height: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .dealer-container-title {
        font-size: 9px;
        height: 25px;
        align-items: center;
        display: flex;
    }

    .dealer-cards {
        width: 245px;
    }

    .dealer-card {
        width: 45px;
        height: 67.5px;
        margin: 0 2px;
    }

    .dealer-card .dealer {
        height: 67.5px;
    }

    .dealer-flip-card {
        width: 45px;
        height: 67.5px;
    }

    .dealer-card img {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
    }

    .dealer-card .animated {
        height: 67.5px;
        border-radius: 5px;
    }

    .dealer-spaces {
        width: 245px;
        top: 40px;
    }

    .dealer-space {
        width: 45px;
        height: 67.5px;
        border-radius: 5px;
        margin: 0 2px;
        opacity: 0.3;
    }

    .dealer-space-circle {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .dealer-space-round {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .dealer-game-status {
        height: 20px;
        padding: 0 12px;
        font-size: 12px;
    }

    .dealer-game-status.smaller {
        font-size: 9px;
        padding: 0 4px;
    }

}

@media screen and (max-height: 600px)and (min-height: 500px) {
    .dealer-container {
        transform: scale(1.5);
        margin-top: 50px;
    }
}


@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .dealer-container {
            width: 170px;
            height: 90px;
        }

        .dealer-container-header {
            height: 12px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .dealer-container-title {
            height: 12px;
            font-size: 9px;
        }

        .dealer-cards {
            width: 170px;
        }

        .dealer-card {
            width: 30px;
            height: 45px;
            margin: 0 2px;
        }

        .dealer-card .dealer {
            height: 45px;
        }

        .dealer-flip-card {
            width: 30px;
            height: 45px;
        }

        .dealer-card img {
            width: 30px;
            height: 45px;
            border-radius: 4px;
        }

        .dealer-card .animated {
            height: 45px;
            border-radius: 5px;
        }

        .dealer-spaces {
            width: 170px;
            top: 22px;
        }

        .dealer-space {
            width: 30px;
            height: 45px;
            border-radius: 4px;
            margin: 0 2px;
            opacity: 0.3;
        }

        .dealer-space-circle {
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }

        .dealer-space-round {
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }

        .dealer-game-status {
            height: 17px;
            padding: 0 7px;
            font-size: 10px;
        }

        .dealer-game-status.smaller {
            font-size: 9px;
            padding: 0 4px;
        }

    }
}