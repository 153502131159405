.purchase-container {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50vh - 150px);
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
}

.purchase-container.visible {
    opacity: 1;
    z-index: 105;
    transition: opacity 0.3s linear, z-index 0.1s linear 0s;
}

.purchase-container.closed {
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s linear, z-index 0.1s linear 0.3s;
}

.purchase-container::after {
    content: "";
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 0px;
    box-shadow: 0 0 200px 150px rgba(0, 0, 0, 0.8);
    width: 300px;
    top: 120px;
}


.purchase-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 20px;
    font-weight: 800;
    color: white;
    text-align: center;
}

.purchase-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    width: 280px;
}

.purchase-button {
    width: 135px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: box-shadow 0.4s;
    background: #8bc34a;
    border: 4px solid #6ea231;
    border-radius: 10px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 18px;
    font-weight: 900;
    color: white;
}

.purchase-button:hover {
    box-shadow: 0 0 20px #8BC34A;
}

.purchase-button.red {
    background: #f44336;
    border: 4px solid #e93124;
}

.purchase-button.red:hover {
    box-shadow: 0 0 20px #f44336;
}

@media screen and (max-width: 600px) {

    .purchase-header {
        font-size: 17px;
    }

    .purchase-content {
        width: 260px;
    }

    .purchase-button {
        width: 124px;
    }
}



@media screen and (min-width: 1960px) {
    .purchase-container {
        transform: scale(1.5);
    }
}