.rule-modal-content {
    background-color: transparent !important;
}

.rule-modal-content {
    width: 100%;
    min-width: 300px;
    max-width: 599px;
    height: 510px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(34, 34, 34, 0.7);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.rule-modal-header {
    position: relative;
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    height: 40px;
    background: #000;
}

.rule-modal-content,
.rule-modal-header {
    align-items: center;
    display: flex;
    width: 100%;
}

.rule-modal-close {
    position: absolute;
    right: 15px;
    border: 1.5px solid #fff;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.rule-modal-close img {
    width: 10px;
    height: 10px;
    object-fit: contain;
}

.rule-modal-inform {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    background: rgba(34, 34, 34, 0.7);
}

.rule-modal-titles {
    background-color: #0000006b;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 30%;
}

.rule-modal-titles div {
    width: 100%;
    width: -webkit-fill-available;
    color: white;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
}

.rule-modal-titles div:hover {
    background-color: rgb(0, 0, 0);
}

.rule-modal-titles div.active {
    color: rgb(246 218 76);
}

.rule-modal-text {
    width: 70%;
    height: 100%;
    font-size: 13px;
    color: rgb(255, 255, 255);
    padding: 0px 10px 30px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.rule-modal-text .title {
    font-size: 20px;
    padding: 8px 0px;
    font-weight: bold;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 20px;
}

.rule-modal-text>div {
    padding-bottom: 20px;
}

.rule-modal-text>div:first-child .title {
    border-top: 0;
}

.rule-modal-text .rule-content p {
    margin-bottom: 10px;
}

.rules-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.rules-table td {
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 5px;
    color: white;
    font-size: 13px;
    font-weight: bold;
}

.rule-component {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rule-component img {
    width: 80%;
    object-fit: contain;
}

.rules-combinations {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
}

.rules-combination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
}

.rules-combination img {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.rules-combination-text {
    font-size: 14px;
    color: white;
    font-weight: bold;
    font-family: "Red Hat Display", 'Google Sans';
    margin-right: 2px;
}


.mobile-rules {
    background-color: transparent;
    padding: 0;
    position: relative;
    right: 0;
    top: 0;
    overflow: hidden;
}

.mobile-rules-inner {
    width: 100%;
    font-size: 12px;
    color: black;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
    height: 495px;
}

.rule-item {
    border-bottom: 1px solid #4e4e4e;
    width: 100%;
    padding: 0px 20px;
}

.rule-item:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.rule-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0px;
    color: #ffffff;
}

.rule-head .rule-title {
    font-size: 12px;
    font-weight: 500;
}

.rule-active-head span {
    transform: rotate(180deg);
}

.rule-hidden-box {
    color: #ffffff;
    overflow: hidden;
    max-height: 0;
}

.rule-hidden-box-active {
    max-height: max-content;
    padding-bottom: 10px;
    /* padding-bottom: 5px; */
}

.rule-mobile-bottom {
    height: 330px;
}

table.dealer-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 10px 0px;
}

.dealer-table tr td,
.dealer-table tr th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.rule-img {
    margin-top: 10px;
    height: 50px;
}

.rule-img.border {
    border: 1px solid #ffffff78;
    border-radius: 5px;
}

.rule-img-big {
    height: 70px;
}

.rule-img-big.widget {
    height: 200px;
}

.rule-img-big.box {
    height: 150px;
}

.rule-button-img {
    margin-top: 10px;
    height: 70px;
}

.rule-combination-img {
    height: 15px;
    margin-top: 10px;
}



/* RULES TABLE */
.rule-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.rule-table td,
.rule-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px 4px;
    font-size: 12px;
}

@media screen and (max-width: 992px) {
    .mobile-rules {
        width: 100%;
        height: 100%;
    }

    .rule-bottom-space {
        padding-top: 150px;
    }

    .mobile-rules-inner {
        height: 100%;
    }

    .rule-item:hover {
        background-color: transparent;
    }

    .rule-item.visible {
        background-color: rgba(0, 0, 0, 0.6);
    }



}

@media screen and (max-width: 600px) {

    .mobile-rules-inner {
        height: calc(100% - 0px);
    }

    .mobile-rules-inner.hidden {
        overflow: hidden;
    }

    .rule-table {
        margin: 10px 0px;
    }

    .rule-table td,
    .rule-table th {
        font-size: 10px;
    }
}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {
        .mobile-rules-inner {
            height: calc(100vh - 0px);
        }
    }
}