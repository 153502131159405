.buttons {
    position: absolute;
    bottom: 30px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.game-button {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    margin: 0 10px;
    font-family: "Red Hat Display", 'Google Sans', monospace;
    font-size: 16px;
    font-weight: 900;
    transition: box-shadow 0.4s ease-in-out;
}

.fold-button {
    background: #f44336;
    border: 4px solid #e93124;
}

.fold-button:hover {
    box-shadow: 0 0 20px #f44336;
}



.bet-button {
    background: #8bc34a;
    border: 4px solid #6ea231;
}

.bet-button:hover {
    box-shadow: 0 0 20px #8bc34a;
}



.sixth-button {
    background: #ff9800;
    border: 4px solid #c37708;
}

.sixth-button:hover {
    box-shadow: 0 0 20px #ff9800;
}


.exchange-button {
    background: #862EC3;
    border: 4px solid #5c2b7d;
    font-size: 14px;
}

.exchange-button:hover {
    box-shadow: 0 0 20px #862EC3;
}




.start-button {
    background: #8bc34a;
    border: 4px solid #6ea231;
    font-size: 17px;
}

.start-button:hover {
    box-shadow: 0 0 20px #8bc34a;
}


.insurance-button {
    background: #03a9f4;
    border: 4px solid #0099de;
    font-size: 14px;
}

.insurance-button:hover {
    box-shadow: 0 0 20px #03a9f4;
}


@media screen and (min-width: 1960px) {
    .buttons {
        transform: scale(1.5);
        bottom: 80px;
    }
}


@media screen and (max-width: 600px) {

    .buttons {
        bottom: 100px;
        transform: scale(0.85);
        flex-wrap: wrap;
    }

    .buttons.four-button {
        bottom: 50px;
        height: 120px;
    }

    .game-button {
        height: 50px;
        margin: 0 5px;
    }

    .exchange-button {
        font-size: 12.5px;
    }

    .insurance-button {
        font-size: 12.5px;
    }

}

@media screen and (max-height: 600px) {
    @media (orientation: landscape) {

        .buttons {
            bottom: 17px;
            transform: scale(0.9);
        }

        .game-button {
            width: 80px;
            height: 40px;
            margin: 0 4px;
            font-size: 14px;
            margin-top: 10px;
        }

        .exchange-button {
            font-size: 11px;
        }

        .insurance-button {
            font-size: 11px;
        }

    }
}